module.exports = {
  selectLanguage: "seleziona la tua lingua",
  displayName: "italiano",
  categories: "categorie",
  read: "Leggere",
  price: "Prezzo",
  lastUpdate: "Ultimo aggiornamento",
  free: "Ora disponibile gratuitamente",
  Software: "Software",
  "Machine Learning": "Apprendimento automatico",
  Python: "Python",
  Azure: "Azure",
  "ARM Template": "ARM Template",
  Tags: "tag",
  Home: "Pagina iniziale",
  "API Management": "API Management",
  "Key Vault": "Key Vault",
  "Contact Form": "Modulo di Contatto",
  "Cancel": "Annulla",
  "Submit": "Invia",
  "Contact Message": "Siamo apprezzati se ci dai feedback sul nostro sito web.",
  "Flutter": "Flutter"
}
